<style scoped lang='scss'>
</style>

<template>
  <div id="privacy">
    <div class="width rullMain">
      <div class="title textCenter weight">隐私条款</div>
      <p class="weight">1. 信息的收集和使用</p>
      <p>
      （1）为了以创建账户、识别用户、回应查询和邮件等方式来为您提供服务、改进软件性能和外观、推荐新功能/新产品等目的，果果慧科技会要求您提供个人信息（包括但不限于您的姓名、电话号码、电子邮件地址等），并且会收集其他与您相关的信息（包括但不限于设备信息、日志信息、位置信息以及其他类似的特征记录）。
      </p>
      <p>
      （2）果果慧科技的用户体验改进计划是基于对使用数据的统计，来诊断系统问题，优化产品体验的计划。我们会使用Google Analytics等工具统计您在使用果果慧科技产品（包括智慧农业APP、果果慧APP）过程中产生的数据信息。
      我们在用户体验改进计划中仅会累计所有用户的总体使用数据，不会收录或上传任何涉及您个人身份信息的内容。前述统计内容包括但不限于：应用启动数，页面访问数，重点按钮点击数，设置项的配置属性等。
      随着果果慧科技产品的后续版本升级，我们统计的范围会有所改变，您下载/安装/使用后续版本果果慧科技产品的行为将被视为您对届时相关改变的同意。
      </p>
      <p>
      （3）果果慧科技的产品和服务中可能含有第三方产品和/或服务的链接或信息。如果您将个人信息填写在其他平台上或通过第三方程序提交/发布，则该方可能会获知您的信息。该第三方应用程序可能有其相应的隐私政策规定，请仔细查看，并在提供个人信息前请仔细考虑清楚。本隐私政策不适用于第三方向您收集信息的行为，果果慧科技无法控制第三方的行为，因此也不会对第三方收集、使用您信息的行为承担责任。
      </p>
      <p>
      （5）果果慧科技将严格遵守有关法律法规和本隐私政策所载明的内容收集、使用您的信息。除非持有果果慧科技官方出具的授权文件，否则果果慧科技的具体工作人员不会直接向您索要个人信息。如遇此类情况，您可以直接与果果慧科技联系。
      </p>
      <p class="weight">2. 信息的披露</p>
      <p>
      （1）除非法律法规规定、政府部门要求或本隐私政策载明的例外情况，果果慧科技会妥善保存您的信息，不予泄露。
      </p>
      <p>
      （2）您于此授权，在以下情形中，果果慧科技可以向第三方披露您的信息而无需再行获得您的同意：
      </p>
      <p>
      （a）如果果果慧科技披露这些信息是用来识别、联系或向可能对果果慧科技的权利或财产、网站用户或者其他任何人（包括其他任何人的权利和财产）造成损害之行为提起诉讼之必要；
      </p>
      <p>
      （b）果果慧科技将个人信息提供给果果慧科技的关联方或其他有合作关系的企业或个人，以便其代表/协助果果慧科技处理这些信息。果果慧科技要求上述各方同意按照果果慧科技的规定、本隐私政策以及有关法律法规之规定来处理这些信息。
      </p>
      <p class="weight">3. 信息安全</p>
      <p>
      （1）果果慧科技将尽合理的努力保障您的信息安全，但是您应当知晓果果慧科技不能完全避免与个人信息安全相关的风险（特别是发生果果慧科技无法控制的情况时，如不可抗力或第三方原因），因此，果果慧科技对隐私信息的维护或保密无法做出任何确定性的保证或承诺。如果果果慧科技知悉其所获取并储存的数据安全受到危害或由于外部行为（包括但不限于外部安全攻击）使用户的非公开信息被披露给不相关第三方，尽管本政策中有其他免责规定，果果慧科技仍将采取其认为适当的合理措施（包括但不限于内部调查、上报并通知执法机构、以及配合执法机构工作等）。同时，果果慧科技也会在其认为适当的情况下采取合法合理措施通知相关用户，告知其被披露的信息以及果果慧科技对该信息的知悉程度。
      </p>
      <p>（2）您应当注意保护您的个人信息安全。例如，不要泄露您的个人密码等。</p>
      <p>
      （3）果果慧科技会采取必要手段、努力防止内部工作人员越权获取/利用/泄露您的信息。
      </p>
      <p>
      （4）果果慧将提供与苹果公司的“健康”应用连接并读写数据的服务。果果慧承诺：
      </p>
      <p class="textIndent">
      （4.1）在没有用户的明确允许下，果果慧将不会向第三方展示应用收集的数据。
      </p>
      <p class="weight">4. 对未成年人信息的收集和使用</p>
      <p>
      （1）果果慧科技要求未成年人在其法定监护人的许可和指导下使用果果慧科技的产品或服务。
      </p>
      <p>
      （2）如果您是未成年人，您需要确保在提供个人信息前，已经获得了您法定监护人的同意。
      </p>
      <p class="weight">5. 本隐私政策的修订</p>
      <p>
        虽然本隐私声明说明了果果慧科技在保护隐私方面所遵循的标准，但这些标准可能会发生变化。如有更新，果果慧科技会在发布修订版本时以果果慧科技认为适当的方式通知您变动内容和生效日期。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    window.scrollTo({top: 0,left: 0});
  },
  methods: {},
};
</script>
